import React from "react";
import BubbleSection from "./components/BubbleSection";
import Header from "./components/Header";
import Table from "./components/Table";
import Footer from "./components/Footer";
import { FaAngleDown } from "react-icons/fa";
import "react-perfect-scrollbar/dist/css/styles.css";
import axiosInstance from "./utils/axios";
// import PerfectScrollbar from "react-perfect-scrollbar";
axiosInstance.initialize();
function App() {
  return (
    <>
      <div className="text-[#228822] bg-[#228822] hidden" />
      <div className="text-[#39DC39] bg-[#39DC39] hidden" />
      <div className="text-[#AA3333] bg-[#AA3333] hidden" />
      <div className="text-[#F07A00] bg-[#F07A00] hidden" />
      <div className="text-[#082c7f] bg-[#082c7f] hidden" />
      <div className="text-[#018FFB] bg-[#018FFB] hidden" />
      <div className="text-[#868686] bg-[#868686] hidden" />
      <Header />
      <BubbleSection />
      <button
        onClick={() => {
          window.scrollTo({
            top: window.innerHeight * 1.1, // 110vh of the viewport height
            behavior: "smooth", // Smoth scroll ka liya
          });
        }}
        className="sm:hidden block fixed bottom-[20px] right-[30px] z-50 bg-[#737373] text-white p-5 text-xl font-medium rounded-full"
      >
        <FaAngleDown />
      </button>

      <Table />
      <Footer />
    </>
  );
}

export default App;
