import axios from "axios";
import { debounce } from "lodash";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useMemo } from "react";
import ChartData from "./Modal/ChartData";
import Modal from "./Modal";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const searchBarRef = useRef(null); // Ref for search input and suggestions

  const handleInputChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const getStocks = useMemo(
    () =>
      debounce(async (value, abortController) => {
        setLoading(true);
        let alpha = true;
        let count = 0;
        try {
          while (alpha) {
            const filters = {
              search: value,
              endDate: moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
              startDate: moment()
                .subtract(count, "days")
                .startOf("day")
                .format("YYYY-MM-DDTHH:mm:ss"),
            };
            const { data: response } = await axios.post(
              "api/stocks/list",
              {
                filters,
              },
              {
                signal: abortController.signal,
              }
            );
            const data = await response.data;
            if (data.length) {
              setSuggestions(data);
              alpha = false;
            } else {
              if (count > 3) {
                alpha = false;
              } else {
                count++;
              }
            }
          }
        } catch (error) {
          console.error("Error fetching search suggestions:", error);
        } finally {
          setLoading(false);
        }
      }, 500),
    []
  );

  useEffect(() => {
    const abortController = new AbortController();
    if (searchTerm?.length) {
      getStocks(searchTerm, abortController);
    }
    return () => {
      abortController.abort();
    };
  }, [searchTerm, getStocks]);

  const openModal = (data) => {
    setIsModalOpen(true);
    setSelectedData(data);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedData(null);
  };

  // Close suggestions when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setSuggestions([]); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchBarRef]);

  return (
    <>
      <div className="relative" ref={searchBarRef}>
        <input
          type="search"
          id="default-search"
          className="block w-full p-2 pl-10 text-sm text-gray-400 rounded-lg focus:ring-purple-500 bg-[#363636]"
          placeholder="Search Stocks...."
          value={searchTerm?.symbol ?? searchTerm}
          onChange={handleInputChange}
        />
        {suggestions.length && !loading ? (
          <ul className="absolute text-gray-400 bg-[#363636] border border-gray-300 w-full rounded-lg mt-1 z-10 max-h-[300px] overflow-y-auto">
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                className="p-2 hover:bg-gray-200 cursor-pointer"
                onClick={() => {
                  setSearchTerm(suggestion);
                  setSuggestions([]);
                  openModal(suggestion);
                }}
              >
                {suggestion.symbol}
              </li>
            ))}
          </ul>
        ) : loading ? (
          <ul className="absolute text-gray-400 bg-[#363636] border border-gray-300 w-full rounded-lg mt-1 z-10 ">
            <li className="p-2 hover:bg-gray-200 cursor-pointer">
              Loading ....
            </li>
          </ul>
        ) : null}
        {isModalOpen ? (
          <Modal isOpen={isModalOpen} onClose={closeModal} data={selectedData}>
            <ChartData selectedData={selectedData} />
          </Modal>
        ) : null}
      </div>
    </>
  );
};

export default SearchBar;
