import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dailyStocks: [],
  weeklyStocks: [],
  monthlyStocks: [],
  yearlyStocks: [],
  pagination: [],
  selectedPage: {
    min: 0,
    max: 100,
  },
};

export const StockSlice = createSlice({
  name: "StockSlice",
  initialState,
  reducers: {
    setDailyStocks: (state, actions) => {
      state.dailyStocks = actions.payload;
    },
    setWeeklyStocks: (state, actions) => {
      state.weeklyStocks = actions.payload;
    },
    setMonthlyStocks: (state, actions) => {
      state.monthlyStocks = actions.payload;
    },
    setYearlyStocks: (state, actions) => {
      state.yearlyStocks = actions.payload;
    },
    setPagination: (state, actions) => {
      state.pagination = actions.payload;
    },
    setSelectedPagination: (state, actions) => {
      state.selectedPage = actions.payload;
    },
  },
});

export const {
  setDailyStocks,
  setWeeklyStocks,
  setMonthlyStocks,
  setYearlyStocks,
  setPagination,
  setSelectedPagination,
} = StockSlice.actions;

export default StockSlice.reducer;
