import React, { useEffect } from "react";
import HeroButton from "./Buttons/HeroButton";
import Bubbles from "./PixiNext/Bubbles";
import { useSelector } from "react-redux";
import { dispatch } from "../reducers/configureStore";
import {
  setHideLoader,
  setSelectedFilter,
  setShowLoader,
} from "../reducers/slices/SettingsSlice";
// import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
import moment from "moment";
import {
  setDailyStocks,
  setPagination,
  setSelectedPagination,
} from "../reducers/slices/StockSlice";
import LoaderModal from "./Modal/LoaderModal";

const BubbleSection = () => {
  const colorScheme = useSelector((state) => state.Settings.colorScheme);
  const loading = useSelector((state) => state.Settings.loader);
  const activeButton = useSelector((state) => state.Settings.selectedFilter);
  const selectedSector = useSelector((state) => state.Settings.selectedSector);
  const selectedAsset = useSelector((state) => state.Settings.selectedAsset);

  useEffect(() => {
    (async () => {
      let alpha = true;
      let count = 1;
      try {
        dispatch(setShowLoader());
        while (alpha) {
          const filters = {
            endDate: moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
            startDate: moment()
              .subtract(count, "days")
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ss"),
            valume: true,
          };
          if (selectedSector !== "ALL SECTORS") {
            filters.sector = selectedSector;
          }
          if (selectedAsset) {
            filters.asset = selectedAsset.id;
          }
          const { data: response } = await axios.post("api/stocks/list", {
            filters,
          });
          if (response.data.length) {
            const stockData = response.data.map((item) => ({
              ...item,
              monthlyPerformance: item?.monthlyPerformance ?? Math.random(),
            }));
            dispatch(setDailyStocks(stockData));
            const length = Math.ceil(stockData.length / 100);
            const dataArray = [];

            for (let i = 0; i < length; i++) {
              const end = (i + 1) * 100;
              dataArray.push({
                min: i * 100,
                max: end > stockData.length ? stockData.length : end,
              });
            }
            dispatch(setPagination(dataArray));
            dispatch(
              setSelectedPagination({
                min: dataArray[0].min,
                max: dataArray[0].max,
              })
            );
            alpha = false;
          } else {
            if (count > 3) {
              dispatch(setDailyStocks([]));
              dispatch(setPagination([{ min: 0, max: 0 }]));
              dispatch(setSelectedPagination({ min: 0, max: 0 }));
              alpha = false;
            } else {
              count++;
            }
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        dispatch(setHideLoader());
      }
    })();
  }, [selectedSector, selectedAsset]);
  return (
    <section className="bg-[#222222] relative h-[100vh] overflow-clip">
      <div className="absolute -top-2 left-5 w-full -z-5">
        <div className="flex flex-row gap-1  md:max-w-[100%]  max-w-[300px] overflow-auto">
          <HeroButton
            text="Day"
            short="dailyPerformance"
            color={colorScheme.success.color}
            borderColor={colorScheme.success.color}
            activeValue={activeButton}
            setActiveButton={(data) => {
              let text = data === "day" ? "dailyPerformance" : data;
              dispatch(setSelectedFilter(text));
            }}
          />
          <HeroButton
            text="Week"
            short="weeklyPerformance"
            color={colorScheme.danger.color}
            borderColor={colorScheme.danger.color}
            activeValue={activeButton}
            setActiveButton={(data) => {
              let text = data === "day" ? "dailyPerformance" : data;
              dispatch(setSelectedFilter(text));
            }}
          />
          <HeroButton
            text="Month"
            short="monthlyPerformance"
            color={colorScheme.success.color}
            borderColor={colorScheme.success.color}
            activeValue={activeButton}
            setActiveButton={(data) => {
              let text = data === "day" ? "dailyPerformance" : data;
              dispatch(setSelectedFilter(text));
            }}
          />
          <HeroButton
            text="YoY"
            short="yearlyPerformance"
            color={colorScheme.danger.color}
            borderColor={colorScheme.danger.color}
            activeValue={activeButton}
            setActiveButton={(data) => {
              let text = data === "day" ? "dailyPerformance" : data;
              dispatch(setSelectedFilter(text));
            }}
          />
          <HeroButton
            text="YTD"
            short="yearToDatePerformance"
            color={colorScheme.success.color}
            borderColor={colorScheme.success.color}
            activeValue={activeButton}
            setActiveButton={(data) => {
              let text = data === "day" ? "dailyPerformance" : data;
              dispatch(setSelectedFilter(text));
            }}
          />
        </div>
      </div>
      <Bubbles />
      {loading ? <LoaderModal /> : null}
    </section>
  );
};

export default BubbleSection;
