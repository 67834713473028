import React, { useState } from "react";
import Modal from "./Modal";
import { useSelector } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import ChartData from "./Modal/ChartData";
import { formatCurrency, roundToOneDecimal } from "../utils/formators";

const Table = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(false);

  const colorScheme = useSelector((state) => state.Settings.colorScheme);
  const Stocks = useSelector((state) => state.Stocks.dailyStocks);
  const selectedPagination = useSelector((state) => state.Stocks.selectedPage);

  const openModal = (data) => {
    setIsModalOpen(true);
    setSelectedData(data);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedData(null);
  };

  return (
    <section
      className=" bg-[#222222] h-auto flex justify-center flex-col items-center"
      id="table"
    >
      {/* <div className="relative overflow-x-auto"> */}
      <PerfectScrollbar
        options={{ suppressScrollX: false, suppressScrollY: true }}
        className="w-[90vw] relative overflow-x-auto"
      >
        <table className="w-full text-sm text-left rtl:text-right text-white">
          <thead className="text-white uppercase bg-[#3D3D3D] text-lg">
            <tr>
              <th scope="col" className="px-6 py-3">
                #
              </th>
              <th scope="col" className="px-6 py-3">
                Stock
              </th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">
                Last Price
              </th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">
                Opening Price
              </th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">
                Market Cap
              </th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">
                Daily
              </th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">
                Weekly
              </th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">
                Monthly
              </th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">
                YoY
              </th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">
                YTD
              </th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">
                24h Volume
              </th>
              <th scope="col" className="px-6 py-3 min-w-[200px]">
                Value
              </th>
            </tr>
          </thead>
          <tbody className="text-lg">
            {/* Map over the tableData array to render each row */}
            {Stocks?.length
              ? Stocks.slice(
                  selectedPagination.min,
                  selectedPagination.max
                ).map((item, index) => (
                  <tr
                    key={index}
                    className=" bg-[#222222] cursor-pointer border-b-[0.5px]"
                    onClick={() => openModal(item)}
                  >
                    <th
                      scope="row"
                      className="px-6 py-2 font-medium whitespace-nowrap"
                    >
                      {index + 1}
                    </th>
                    <td className="">
                      <div className="bg-[#3D3D3D] cursor-pointer w-max px-4 py-2 rounded-md flex font-semibold items-center">
                        {item.symbol}
                      </div>
                    </td>
                    <td className="px-6 py-2">
                      ₦ {formatCurrency(item.close).replace("NGN", "")}
                    </td>
                    <td className="px-6 py-2">
                      ₦ {formatCurrency(item.open).replace("NGN", "")}
                    </td>
                    <td className="px-6 py-2">
                      {item.marketCap ? "₦" : ""} {item.marketCap ?? "--"}
                    </td>
                    <td
                      className={`px-6 py-2 ${
                        roundToOneDecimal(item.dailyPerformance) < 0
                          ? `bg-[${colorScheme.danger.color}]`
                          : roundToOneDecimal(item.dailyPerformance) === 0
                          ? `bg-none`
                          : `bg-[${colorScheme.success.color}]`
                      }`}
                    >
                      {roundToOneDecimal(item.dailyPerformance)} %
                    </td>
                    <td
                      className={`px-6 py-2 ${
                        roundToOneDecimal(item.weeklyPerformance) < 0
                          ? `bg-[${colorScheme.danger.color}]`
                          : roundToOneDecimal(item.weeklyPerformance) === 0
                          ? `bg-none`
                          : `bg-[${colorScheme.success.color}]`
                      }`}
                    >
                      {roundToOneDecimal(item.weeklyPerformance)} %
                    </td>
                    <td
                      className={`px-6 py-2 ${
                        roundToOneDecimal(item.monthlyPerformance) < 0
                          ? `bg-[${colorScheme.danger.color}]`
                          : roundToOneDecimal(item.monthlyPerformance) === 0
                          ? `bg-none`
                          : `bg-[${colorScheme.success.color}]`
                      }`}
                    >
                      {roundToOneDecimal(item.monthlyPerformance)} %
                    </td>
                    <td
                      className={`px-6 py-2 ${
                        roundToOneDecimal(item.yearlyPerformance) < 0
                          ? `bg-[${colorScheme.danger.color}]`
                          : roundToOneDecimal(item.yearlyPerformance) === 0
                          ? `bg-none`
                          : `bg-[${colorScheme.success.color}]`
                      }`}
                    >
                      {roundToOneDecimal(item.yearlyPerformance)} %
                    </td>
                    <td
                      className={`px-6 py-2 ${
                        roundToOneDecimal(item.yearToDatePerformance) < 0
                          ? `bg-[${colorScheme.danger.color}]`
                          : !item.yearToDatePerformance
                          ? `bg-none`
                          : `bg-[${colorScheme.success.color}]`
                      }`}
                    >
                      {roundToOneDecimal(item.yearToDatePerformance) ?? 0} %
                    </td>
                    <td className="px-6 py-2">
                      Qty. {formatCurrency(item.volume).replace("NGN", "")}
                    </td>
                    <td className="px-6 py-2">
                      ₦{" "}
                      {formatCurrency(item.volume * item.close).replace(
                        "NGN",
                        ""
                      )}
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </PerfectScrollbar>
      {!Stocks.length ? (
        <div className="flex justify-center text-white my-5 ">
          <div className="bg-[#3D3D3D] w-max px-4 py-2 rounded-md flex font-semibold items-center">
            There is no data to show.
          </div>
        </div>
      ) : null}
      {/* </div> */}

      <Modal isOpen={isModalOpen} onClose={closeModal} data={selectedData}>
        <ChartData selectedData={selectedData} />
      </Modal>
    </section>
  );
};

export default Table;
